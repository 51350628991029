export const environment = {
    production: true,
    logErrorInConsole: false,
    environmentName: "production",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    oauthBaseAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/auth/",
    usersAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/users/",
    rolesAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/roles/",
    organizationsAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/organizations/",
    profileAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/profile/",
    resetPasswordAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/resetpassword/",
    languagesAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/languages/",
    smtpMailAccountsAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/mailaccounts/",
    mailtemplatesAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/mailtemplates/",
    systemLogMessagesAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/systemlogs/",
    systemJobPerformancesAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/systemjobs/",
    systemResourceSnapshotsAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/systemresource/",
    systemErrorsAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/systemerrors/",
    registrationConfigAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/registrationconfig/",
    breakoutGroupsAPI: "https://beigene-investigator-api-oqmzmsekza-ey.a.run.app/api/v1/breakoutgroups/"
};
